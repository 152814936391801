import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import useCollapseDrawer from '../hooks/useCollapseDrawer';
import { HEADER, NAVBAR } from '../config';
import DashboardHeader from '../components/layout-header';
import NavbarVertical from '../components/layout-navbar/NavbarVertical';

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 20,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT - 5,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

export default function Navigation() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const [open, setOpen] = useState(false);
  const [showAuthPage, setShowAuthPage] = useState<boolean>(false);

  useEffect(() => {
    const isAuthRoute = window.location.pathname.startsWith('/auth');

    if (!isAuthRoute) return;

    setShowAuthPage(true);
  }, []);

  return showAuthPage ? (
    <div id="single-spa-application:spa-login" />
  ) : (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <div id="single-spa-application:spa-order" />
        <div id="single-spa-application:spa-users" />
      </MainStyle>
    </Box>
  );
}
