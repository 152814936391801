import SvgIconStyle from '../SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  invoice: getIcon('ic_invoice'),
  users: getIcon("ic_users"),
};

const navConfig = [
  {
    subheader: 'Menú',
    items: [
      {
        title: 'Órdenes',
        path: '/orders',
        icon: ICONS.invoice,
        children: [
          { title: 'Listado de órdenes', path: '/orders' },
        ],
      },
      {
        title: 'Usuarios',
        path: '/users',
        icon: ICONS.users,
        children: [
          { title: 'Listado de usuarios', path: '/users' },
        ],
      },
    ],
  },
];

export default navConfig;
